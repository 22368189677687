import React, { ReactElement } from "react";
import { GetServerSideProps, InferGetServerSidePropsType } from "next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import {
  GetPageDocument,
  GetPageQuery,
  GetPageQueryVariables,
} from "@graphql/generated/graphql";
import config from "@lib/config";
import env from "@beam-australia/react-env";
import { getClient } from "@graphql/client";
import DefaultLayout from "@components/layout/DefaultLayout";
import renderer, { Block } from "@components/cms/Components";

type Props = {
  content: Block;
};

export default function CmsPage({
  content,
}: InferGetServerSidePropsType<typeof getServerSideProps>) {
  return <>{renderer(content)}</>;
}

CmsPage.getLayout = function getLayout(page: ReactElement) {
  return <DefaultLayout>{page}</DefaultLayout>;
};

export const getServerSideProps: GetServerSideProps<Props> = async (
  context
) => {
  const client = getClient();
  const slug = context.params?.slug || "index";
  const locale = context.locale || config.defaultLanguageCode;

  const { data } = await client
    .query<GetPageQuery, GetPageQueryVariables>({
      query: GetPageDocument,
      variables: {
        slug: `${env("CMS_BASE_PATH")}/${slug}`.replace(/^\/+|\/+$/g, ""),
        locale: locale,
      },
    })
    .catch(() => {
      return {
        data: { pageContent: {} },
      };
    });

  if (!data.pageContent) {
    return {
      notFound: true,
    };
  }

  // @todo CTPT-423 migrate to App Router
  const { data: navigationContent } = await client
    .query({
      query: GetPageDocument,
      variables: {
        slug: env("CMS_BASE_PATH"),
        locale: locale,
      },
    })
    .catch(() => {
      return {
        data: {},
      };
    });

  return {
    props: {
      content: data.pageContent,
      navigationContent: navigationContent,
      ...(await serverSideTranslations(locale, [
        "common",
        "account",
        "search",
      ])),
    },
  };
};
